<template>
  <div>
    
  </div>
</template>

<script>
import {getTransLink} from '@/api/translink'
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {
		console.log(this.$route.params.code)
		this._loadData()
  },
  methods: {
		_loadData() {
			getTransLink({
				code: this.$route.params.code
			}).then(res => {
				if(res.errCode === 0) {
					let url = res.data.t
					window.location = url
					return false
				}
			}).catch(err => {
				console.log(err)
			})
		},
	}
};
</script>

<style scoped></style>
